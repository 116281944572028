const lang = {

    it: {
        menu: {
            home: 'Home',
            newStudent: 'Nuovo studente',
            studentIndex: 'Elenco studenti',
            logout: 'Uscire',
            reviews: 'Recensioni'
        },
        form: {
            mandatoryFields: 'Campi obbligatori',
            save: 'Salva',
            ok: 'Ok'
        },
        login: {
            username: 'Utente',
            password: 'Password',
            login: 'Accedi',
            invalidCredentials: 'Utente non trovato o credenziali non valide',
            invalidSchool: 'Scuola non registrata'
        },
        student: {
            name: 'Nome',
            surname: 'Cognome',
            email: 'E-mail',
            school: 'Scuola',
            courseDate: 'Data corso (gg-mm-aaaa)',
            dateFormat: 'gg-mm-aaaa',
            finalMark: 'Voto finale',
            residenceCity: 'Città di residenza',
            zip: 'CAP',
            residenceNation: 'Nazione residenza',
            bornCity: 'Città di nascita',
            bornNation: 'Nazione di nascita',
            phonePrefix: 'Prefisso telefonico',
            cellphone: 'Cellulare',
        },
        studentIndex: {
            studentIndex: 'Elenco studenti',
            surnameAndName: 'Cognome e nome',
            email: 'E-mail',
            finalMark: 'Voto finale',
            courseDate: 'Data corso',
            creationDate: 'Data creazione',
            pin: 'Pin',
            actions: 'Azioni',
            student: 'Studente',
            school: 'Scuola',
            residence: 'Residenza',
            bornDate: 'Nascita',
            dateOfCreation: 'Data di creazione',
            confirmDelete: 'Sicuro di voler cancellare questo studente?',
            studentDeleted: 'Studente cancellato'
        },
        newStudent: {
            newStudent: 'Nuovo studente',            
        },
        modifyStudent: {
            modifyStudent: 'Modifica studente'
        },
        reviews: {
            title: 'Recensioni',
            email: 'E-mail',
            stars: 'Stelle',
            review: 'Recensione',
            language: 'Lingua',
            date: 'Data',
            visible: 'Visibile',
            openReview: 'Apri'
        }
    },
    en: {
        menu: {
            home: 'Home',
            newStudent: 'New student',
            studentIndex: 'Student index',
            logout: 'Logout',
            reviews: 'Reviews'
        },
        form: {
            mandatoryFields: 'Mandatory fields',
            save: 'Save',
            ok: 'Ok'
        },
        login: {
            username: 'User',
            password: 'Password',
            login: 'Login',
            invalidCredentials: 'User not found or invalid credentials',
            invalidSchool: 'School not available'
        },
        student: {
            name: 'Name',
            surname: 'Surname',
            email: 'E-mail',
            school: 'School',
            courseDate: 'Course date (dd-mm-yyyy)',
            dateFormat: 'dd-mm-yyyy',
            finalMark: 'Final mark',
            residenceCity: 'City of residence',
            zip: 'ZIP',
            residenceNation: 'Nation of residence',
            bornCity: 'City of birth',
            bornNation: 'Nation of birth',
            phonePrefix: 'Phone prefix',
            cellphone: 'Cellphone',
        },
        studentIndex: {
            studentIndex: 'Student index',
            surnameAndName: 'Surname and name',
            email: 'E-mail',
            finalMark: 'Final mark',
            courseDate: 'Date of course',
            creationDate: 'Data of creation',
            pin: 'Pin',
            actions: 'Actions',
            student: 'Student',
            school: 'School',
            residence: 'Residence',
            bornDate: 'Birth',
            dateOfCreation: 'Date of creation',
            confirmDelete: 'Are you sure you want to delete this student?',
            studentDeleted: 'Student deleted'
        },
        newStudent: {
            newStudent: 'New student',            
        },
        modifyStudent: {
            modifyStudent: 'Modify student'
        },
        reviews: {
            title: 'Reviews',
            email: 'E-mail',
            stars: 'Stars',
            review: 'Review',
            language: 'Language',
            date: 'Date',
            visible: 'Visible',
            openReview: 'Open'
        }
    },
};

export default lang