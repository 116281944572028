<template>
    <div>
        
        <div class="container mt-4">
            <div class="row">
                <div class="row text-center">
                    <h1>
                        AVPN - 
                        <span v-if="$store.getters.currentSchoolId != 0">
                            {{$store.getters.currentSchool[0].name}}
                        </span>
                        <span v-else>
                            Admin
                        </span>
                    </h1>

                    <h5>
                        Manager
                    </h5>

                    <debug-dialog>
                        <router-link :to="{name: 'Students/Edit', params: {id: 5}}">
                            test edit
                        </router-link>

                        <br /><br />

                        <button @click="testAlert">Test alert</button>

                        <br /><br />
                        Connecting api:
                        {{ apiResponse }}

                        <br /><br />
                        <h1>Login info</h1>
                        Is logged in: {{$store.getters.isLoggedIn}}
                    </debug-dialog>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {

    data() {
        return {
            apiResponse: false,
        }
    },

    methods: {
        pingApi() {
            this.$axios.post('Hello/').then(response => {
                this.apiResponse = response;
            })
        },

        testAlert() {
            this.$store.dispatch('showAlert', {type: 'success', text: 'Test text alert'})
        },
    },

    mounted() {
        if (this.$debug)
            this.pingApi();
    }
    
}
</script>