import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        alert: {
            display: false,
            type: 'primary',
            text: '',
        },

        user: {
            id: null,
            schoolId: null,
        },

        schools: [],
        language: 'it',
    },
    
    getters: {
        alert(state) {
            return state.alert;
        },

        isLoggedIn(state) {
            return (state.user.id && state.user.schoolId);
        },

        currentSchool(state) {
            // 0 manages all schools
            if (state.user.schoolId==0)
                return state.schools;

            return [state.schools.find(el => el.id == state.user.schoolId)];
        },

        currentSchoolId(state) {
            return parseInt(state.user.schoolId);
        },

        language: state => {
            return state.language;
        },
    },

    mutations: {
        alert(state, data) {
            state.alert = {
                type: data.type,
                text: data.text,
                display: true,
            }
        },

        hideAlert(state) {
            state.alert.display = false;
        },

        login(state, data) {
            state.user = {
                id: data.id,
                schoolId: data.schoolId,
            }
        },

        logout(state) {
            state.user = {
                id: null,
                schoolId: null,
            },
            
            router.push({name: 'Login'})
        },

        setSchools(state, data) {
            state.schools = data.schools;
        },

        setLanguage: (state, value) => {
            state.language = value;
        },
    },

    actions: {
        showAlert(context, data) {
            context.commit('alert', data)
        },

        hideAlert(context) {
            context.commit('hideAlert');
        },

        login(context, data) {
            context.commit('login', data)
        },

        logout(context) {
            context.commit('logout')
        },

        setSchools(context, data) {
            context.commit("setSchools", data);
        },

        changeLanguage: ({commit}, params) => {
            commit('setLanguage', params.language);
        },
    },

    modules: {
        
    },
})