<template>
    <div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="alert alert-dismissible fade show" :class="'alert-'+type" role="alert">
                        {{text}}
                        <button type="button" class="btn-close" @click="$store.commit('hideAlert')" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['type', 'text']
}
</script>
