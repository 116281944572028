<template>
    <div>
        <div class="container">

            <div class="modal" tabindex="-1" id="studentModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{t('student.student')}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="!viewStudentData">
                        <p>Loading content...</p>
                    </div>
                    <div v-else class="modal-body">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td>{{t('studentIndex.surnameAndName')}}</td>
                                    <td>{{viewStudentData.name}} {{viewStudentData.surname}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.email')}}</td>
                                    <td>{{viewStudentData.email}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.school')}}</td>
                                    <td>
                                        {{$store.getters.currentSchool.find(el => el.id == viewStudentData.school_id).name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.courseDate')}}</td>
                                    <td>{{viewStudentData.course_date || '-'}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.finalMark')}}</td>
                                    <td>{{viewStudentData.final_mark || '-'}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.residence')}}:</td>
                                    <td>{{viewStudentData.residence_city}}, {{viewStudentData.residence_cap}} {{viewStudentData.residence_nation}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.bornDate')}}:</td>
                                    <td>{{viewStudentData.born_city}}, {{viewStudentData.born_nation}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('student.cellphone')}}:</td>
                                    <td>{{viewStudentData.phone_prefix}} {{viewStudentData.cellphone}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.creationDate')}}:</td>
                                    <td>{{viewStudentData.created_at}}</td>
                                </tr>
                                <tr>
                                    <td>{{t('studentIndex.pin')}}:</td>
                                    <td>{{viewStudentData.pin}}</td>
                                </tr>                                
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Ok</button>
                    </div>
                    </div>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col">
                    <h3>{{t('studentIndex.studentIndex')}}</h3>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{t('studentIndex.surnameAndName')}}</th>
                        <th scope="col">{{t('studentIndex.email')}}</th>
                        <th scope="col" v-if="$store.getters.currentSchool.length>1">{{t('studentIndex.school')}}</th>
                        <th scope="col">{{t('studentIndex.finalMark')}}</th>
                        <th scope="col">{{t('studentIndex.courseDate')}}</th>
                        <th scope="col">{{t('studentIndex.creationDate')}}</th>
                        <th scope="col">{{t('studentIndex.pin')}}</th>
                        <th scope="col">{{t('studentIndex.actions')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(student, index) in students" :key="index">
                        <td>{{student.id}}</td>
                        <td>{{student.surname}}, {{student.name}}</td>
                        <td>{{student.email}}</td>
                        <td v-if="$store.getters.currentSchool.length>1">{{$store.getters.currentSchool.find(el => el.id == student.school_id).name}}</td>
                        <td>{{student.final_mark || '-'}}</td>
                        <td>{{student.course_date || '-'}}</td>
                        <td>{{student.created_at}}</td>
                        <td>{{student.pin}}</td>
                        <td>
                            <button type="button" class="btn btn-primary" @click="actionView(student.id)">
                                <i class="fa fa-eye"></i>
                            </button>
                            <button type="button" class="btn btn-success" @click="actionEdit(student.id)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button type="button" class="btn btn-danger" @click="actionDelete(student.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>

const bootstrap = require('bootstrap')

export default {
    data() {
        return {
            students: false,

            viewStudentData: false,
        }
    },

    methods: {
        getAll() {
            this.$axios.post('Student/', {funct: 'getAll', school_id: this.$store.getters.currentSchoolId}).then(response => {
                if (response.data.ok) {
                    this.students = response.data.values.students;
                }
            })
        },

        // Actions
        actionView(id) {
            this.student = false;

            let modal = new bootstrap.Modal(document.getElementById('studentModal'))
            modal.toggle();
            
            // Get user data
            this.$axios.post('Student/', {funct: 'getStudent', id}).then(response => {
                if (response.data.ok) {
                    this.viewStudentData = response.data.values.student;
                }
            })
        },

        actionEdit(id) {
            this.$router.push({name: 'Students/Edit', params: {id}})
        },

        actionDelete(id) {
            if (!confirm(this.t('studentIndex.confirmDelete'))) {
                return;
            }

            this.$axios.post('Student/', {funct: 'delete', id}).then(response => {
                if (response.data && response.data.ok) {
                    this.getAll();
                    this.$store.dispatch('showAlert', {type: 'success', text: this.t('studentIndex.studentDeleted')});                    
                }
            })
        },
    },

    mounted() {
        this.getAll();
    },    
}
</script>

<style scoped>
.btn {
    margin-right: 15px;
}
</style>