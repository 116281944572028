<template>
  <div id="app">
    <Navbar />

    <Alert v-if="this.$store.getters.alert.display" :text="this.$store.getters.alert.text" :type="this.$store.getters.alert.type" />
    
    <router-view></router-view>

    <Footer />
  </div>
</template>

<script>

import Alert from '@/components/Alert.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',

  components: {
    Alert,
    Footer,
    Navbar,
  },

  data() {
    return {
      
    }
  },

  mounted() {
    // If not logged in, send to login page
    if (!this.$store.getters.isLoggedIn && this.$route.name != 'Login')
        this.$router.push({name: 'Login'});
  },
}
</script>
