<template>
    <div>
        <div class="container">
            <div class="row mb-3">
                <div class="col">
                    <h3 v-if="!this.edit">{{t('newStudent.newStudent')}}</h3>
                    <h3 v-else>{{t('modifyStudent.modifyStudent')}}</h3>
                </div>
            </div>

            <!-- Main form -->
            <form @submit="processForm">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name" class="form-label">{{t('student.name')}}*</label>
                            <input v-model="form.name" type="text" class="form-control" :placeholder="t('student.name')" required>
                        </div>                    
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="surname" class="form-label">{{t('student.surname')}}</label>
                            <input v-model="form.surname" type="text" class="form-control" :placeholder="t('student.surname')" required>
                        </div>                    
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="email" class="form-label">{{t('student.email')}}*</label>
                            <input v-model="form.email" type="email" class="form-control" :placeholder="t('student.email')" required>
                        </div>                    
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="school_id" class="form-label">{{t('student.school')}}*</label>
                            
                            <select v-model="form.school_id" class="form-control">
                                <option v-for="(school, index) in $store.getters.currentSchool" :key="index" :value="school.id">
                                    {{school.name}}
                                </option>
                            </select>
                        </div>                    
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="course_date" class="form-label">{{t('student.courseDate')}}</label>
                            <input v-model="form.course_date" type="text" class="form-control" :placeholder="t('student.dateFormat')">
                        </div>                    
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="final_mark" class="form-label">{{t('student.finalMark')}}</label>
                            <input v-model="form.final_mark" type="text" class="form-control" :placeholder="t('student.finalMark')">
                        </div>                    
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="residence_city" class="form-label">{{t('student.residenceCity')}}*</label>
                            <input v-model="form.residence_city" type="text" class="form-control" :placeholder="t('student.residenceCity')" required>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="residence_cap" class="form-label">{{t('student.zip')}}*</label>
                            <input v-model="form.residence_cap" type="text" class="form-control" :placeholder="t('student.zip')" required>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="residence_nation" class="form-label">{{t('student.residenceNation')}}*</label>
                            <input v-model="form.residence_nation" type="text" class="form-control" :placeholder="t('student.residenceNation')" required>
                        </div>                    
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="born_city" class="form-label">{{t('student.bornCity')}}*</label>
                            <input v-model="form.born_city" type="text" class="form-control" :placeholder="t('student.bornCity')" required>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="born_nation" class="form-label">{{t('student.bornNation')}}*</label>
                            <input v-model="form.born_nation" type="text" class="form-control" :placeholder="t('student.bornNation')" required>
                        </div>                    
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="phone_prefix" class="form-label">{{t('student.phonePrefix')}}*</label>
                            <input v-model="form.phone_prefix" type="text" class="form-control" placeholder="+xx" required>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="cellphone" class="form-label">{{t('student.cellphone')}}*</label>
                            <input v-model="form.cellphone" type="text" class="form-control" :placeholder="t('student.cellphone')" required>
                        </div>                    
                    </div>
                </div>

                <hr />

                <small>* {{t('form.mandatoryFields')}}</small>

                <!-- Confirm button -->
                <div class="row justify-content-center mt-2">
                    <div class="col-12 col-md-4">
                        <div class="d-grid gap-2">
                            <button type="submit" class="btn btn-block btn-success">{{t('form.save')}}</button>     
                        </div>
                    </div>
                </div>
            </form>

        </div>

        <!-- Debug -->
        <debug-dialog>
            edit: {{edit}}
            <br />
            id: {{id}}
        </debug-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            edit: false,
            id: false,

            form: {
                name: null,
                surname: null,
                email: null,
                school_id: null,
                course_date: null,
                final_mark: null,
                residence_city: null,
                residence_cap: null,
                residence_nation: null,
                born_city: null,
                born_nation: null,
                phone_prefix: null,
                cellphone: null,
            }
        }
    },

    methods: {
        loadStudent() {
            this.$axios.post('Student/', {funct: 'getStudent', id: this.id}).then(response => {
                if (response.data && response.data.ok) {
                    // OK
                    this.form = response.data.values.student;
                } else {
                    // KO
                    this.$store.dispatch('showAlert', {type: 'danger', text: 'Errore. Studente non trovato.'})
                }
            });
        },

        processForm(e) {
            e.preventDefault();

            if (!this.edit) {
                // New student
                this.$axios.post('Student/', {funct: 'save', student: this.form}).then(response => {
                    if (response.data && response.data.ok) {
                        // OK
                        this.$store.dispatch('showAlert', {type: 'success', text: 'Nuovo studente registrato'});
                        this.$router.push({name: 'Students'})
                    } else {
                        // KO
                        this.$store.dispatch('showAlert', {type: 'danger', text: 'Errore. Contattare l\'amministratore'})
                    }
                });
                return;
            } else {
                // Edit student
                this.$axios.post('Student/', {funct: 'edit', student: this.form, id: this.id}).then(response => {
                    if (response.data && response.data.ok) {
                        // OK
                        this.$store.dispatch('showAlert', {type: 'success', text: 'Studente aggiornato'});
                        this.$router.push({name: 'Students'})
                    } else {
                        // KO
                        this.$store.dispatch('showAlert', {type: 'danger', text: 'Errore. Contattare l\'amministratore'})
                    }
                });
                return;
            }
        }
    },
    
    mounted() {
        // Check if it is creating or editing an entity
        if (this.$route.params.id) {
            this.edit = true;
            this.id = this.$route.params.id;      
            this.loadStudent()
        }

        // select default school
        this.form.school_id = this.$store.getters.currentSchool[0].id;

        // Debug values
        if (!this.edit && this.$debug)
            this.form = {
                name: 'User',
                surname: 'Surname',
                email: 'email@test.com',
                residence_city: 'Torino',
                residence_cap: '10093',
                residence_nation: 'Italy',
                born_city: 'Buenos Aires',
                born_nation: 'Argentina',
                phone_prefix: '54',
                cellphone: '3401999888',
            }
    }
}
</script>