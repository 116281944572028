<template>
    <div>
        <div class="container">
            <!-- header logo -->
            <div class="row">
                <div class="col col-12 col-md-12 text-center mb-4">
                    <img class="img-responsive homepage__logo" src="@/assets/logo_brand.png" />
                </div>
            </div>

            <language-selector />

            <!-- Main form -->
            <form @submit="processForm">
                <div class="row justify-content-center mt-3">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="username" class="form-label">{{t('login.username')}}</label>
                            <input v-model="form.username" type="text" class="form-control" :placeholder="t('login.username')" required>
                        </div>                    
                    </div>
                    
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="password" class="form-label">{{t('login.password')}}</label>
                            <input v-model="form.password" type="password" class="form-control" :placeholder="t('login.password')" required>
                        </div>                    
                    </div>
                </div>

                

                <!-- Confirm button -->
                <div class="row justify-content-center mt-2">
                    <div class="col-12 col-md-4">
                        <small>* {{t('form.mandatoryFields')}}</small>

                        <br /><br />

                        <div class="d-grid gap-2">
                            <button type="submit" class="btn btn-block btn-success">{{t('login.login')}}</button>     
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>

import LanguageSelector from './../components/LanguageSelector'

export default {
    components: {
        LanguageSelector
    },

    data() {
        return {
            form: {
                username: null,
                password: null,
            }
        }
    },

    methods: {
        processForm(e) {
            e.preventDefault();

            this.$axios.post('Auth/', {funct: 'login', user: this.form}).then(response => {
                if (response.data.ok) {
                    this.$store.commit('login', {id: response.data.values.id, schoolId: response.data.values.school_id});
                    // Hide alert messages
                    this.$store.commit('hideAlert');

                    this.$router.push({name: 'Index'});
                } else {
                    this.$store.dispatch('showAlert', {type: 'danger', text: this.t('login.invalidCredentials')})
                }
            })
        },

        getSchools() {
            this.$axios.post('School/', {funct: 'getAll'}).then(response => {
                if (response.data.ok) {
                    this.$store.commit('setSchools', {schools: response.data.values.schools});
                } else {
                    this.$store.dispatch('showAlert', {type: 'danger', text: this.t('login.invalidSchool')})
                }
            })
        },

    },

    mounted() {
        this.getSchools();

        if (this.$debug)
            this.form = {
                username: 'admin',
                password: '123456'
            }
    }
}
</script>
