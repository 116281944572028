<template>
    <div>

        <div class="container">
            <div class="row mb-3">
                <div class="col">
                    <h3>{{t('reviews.title')}}</h3>                    
                </div>
            </div>

            <div class="modal" tabindex="-1" id="reviewModal">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{t('reviews.title')}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" v-if="currentReview">
                        {{currentReview.review}}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Ok</button>
                    </div>
                    </div>
                </div>
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{t('reviews.email')}}</th>
                        <th scope="col">{{t('reviews.stars')}}</th>
                        <th scope="col">{{t('reviews.review')}}</th>
                        <th scope="col">{{t('reviews.language')}}</th>                        
                        <th scope="col">{{t('reviews.date')}}</th>
                        <th scope="col">{{t('reviews.visible')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(review, index) in reviews" :key="index">
                        <td>{{review.id}}</td>
                        <td>{{review.email}}</td>
                        <td>
                            <!-- Stars -->
                            <i class="fa-solid fa-xs fa-star" v-for="n in parseInt(review.stars)" :key="'star-'+n"></i>
                            <i class="fa-regular fa-xs fa-star" v-for="n in (5-parseInt(review.stars))" :key="'star-outlined-'+n"></i>
                            {{review.stars}}
                        </td>
                        <td>
                            <!-- Open review -->
                            <button type="button" class="btn btn-success" @click="openReview(review)">{{t('reviews.openReview')}}</button> 
                        </td>
                        <td>
                            <!-- Language -->
                            <span v-if="review.language=='en'" class="fi fi-us"></span>
                            <span v-if="review.language=='es'" class="fi fi-mx"></span>
                        </td>
                        <td>{{review.created_at}}</td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" v-model="review.visible" @click="toggleVisible(review)" type="checkbox" :disabled="loading">
                        
                                <!-- Visible on website -->
                                <i v-if="review.visible == 0" class="fa fa-lg fa-xmark text-danger"></i>
                                <i v-if="review.visible == 1" class="fa fa-lg fa-check text-success"></i>   
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>
</template>

<script>

const bootstrap = require('bootstrap')

export default {

    data() {
        return {
            reviews: null,
            loading: false,
            currentReview: null,
        }
    },

    methods: {
        getAll() {
            this.$axios.post('Review/', {funct: 'getAll', school_id: this.currentSchoolId}).then(response => {
                if (response.data && response.data.ok) {
                    // Adjust visible value
                    response.data.values.reviews.forEach(review => {
                        review.visible = parseInt(review.visible)
                    })
                    this.reviews = response.data.values.reviews;
                }
            });
        },

        openReview(review) {
            this.currentReview = review;
            let modal = new bootstrap.Modal(document.getElementById('reviewModal'))
            modal.toggle();
        },

        toggleVisible(review) {
            this.loading = true;

            let visible = (!review.visible || review.visible==0) ? 1 : 0;

            this.$axios.post('Review/', {funct: 'toggleVisible', id: review.id, visible}).then(response => {
                if (response.data && response.data.ok) {
                    this.loading = false;
                }
            })
        },
    },

    mounted() {
        this.getAll();
    },

    computed: {
        currentSchoolId() {
            return this.$store.getters.currentSchoolId;
        }
    },
    
}
</script>

<style>

.fa-star {
    color: gold;
}

</style>