var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark bg-dark text-white mb-5"},[(_vm.$store.getters.isLoggedIn)?_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarScroll"}},[_c('img',{staticClass:"img-responsive logo d-none d-lg-block",attrs:{"src":require("@/assets/logo_brand.png")}}),_c('ul',{staticClass:"navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll",staticStyle:{"--bs-scroll-height":"100px"}},[_vm._l((_vm.menu),function(link,index){return [(
                                // Link not limited to any school
                                !link.schoolId && !link.schoolIds
                            ||
                                // Link limited to one school only
                                link.schoolId && link.schoolId == _vm.currentSchoolId
                            ||
                                // Link limited to more than one school
                                link.schoolIds && link.schoolIds.includes(_vm.currentSchoolId)
                            )?_c('li',{key:index,staticClass:"nav-item",class:{'dropdown': link.submenu}},[(!link.submenu)?[_c('router-link',{staticClass:"nav-link",class:{
                                    'active': _vm.$route.path == link.to
                                    },attrs:{"to":link.to}},[_vm._v(" "+_vm._s(link.label)+" ")])]:[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"navbarDropdown","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" "+_vm._s(link.label)+" ")]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-dark",attrs:{"aria-labelledby":"navbarDropdown"}},_vm._l((link.submenu),function(sublink,subindex){return _c('li',{key:subindex},[_c('router-link',{staticClass:"dropdown-item",class:{
                                            'active': _vm.$route.path == sublink.to
                                            },attrs:{"to":sublink.to}},[_vm._v(" "+_vm._s(sublink.label)+" ")])],1)}),0)]],2):_vm._e()]}),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" "+_vm._s(_vm.t('menu.logout'))+" ")])])],2),_c('language-selector')],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarScroll","aria-controls":"navbarScroll","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }