import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index.vue'

import Login from '@/views/Login.vue'

import Students from '@/views/students/Index.vue'
import StudentsNewEdit from '@/views/students/NewEdit.vue'

import Reviews from '@/views/reviews/Index.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/index', name: 'Index', component: Index },

  { path: '/', name: 'Login', component: Login },

  { path: '/students/', name: 'Students', component: Students },
  { path: '/students/new/', name: 'Students/New', component: StudentsNewEdit },
  { path: '/students/edit/:id', name: 'Students/Edit', component: StudentsNewEdit },

  { path: '/reviews', name: 'Reviews', component: Reviews },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
