import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import lang from './lang'
import CountryFlag from 'vue-country-flag'

// flag icons
import "/node_modules/flag-icons/css/flag-icons.min.css"

// axios config
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// vue config
Vue.config.productionTip = false

// global values
Vue.prototype.$api = process.env.VUE_APP_API_URL
Vue.prototype.$axios = axios
Vue.prototype.$store = store
Vue.prototype.$lang = lang;

// Set debug mode
Vue.prototype.$debug = false

// Flags
Vue.component('country-flag', CountryFlag)

// Mixin
Vue.mixin({
  methods: {

    t(key) {
      const lang = this.$lang[this.$store.getters.language];
      let subkey = key.split('.');

      if (subkey.length==2) {
        if (lang[subkey[0]] && lang[subkey[0]][subkey[1]])
          return lang[subkey[0]][subkey[1]];
        else
          return '';
      } else {
        return lang[key] || '';
      }
    },

  },
});

// Disable debug in production
if (process.env.NODE_ENV != 'development')
  Vue.prototype.$debug = false;

// Load global components
import DebugDialog from '@/components/DebugDialog.vue'
Vue.component('debug-dialog', DebugDialog);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
