<template>
	<div class="row">
		<div class="col text-center">
			<a href="#" @click="changeLanguage('it')" title="Italiano">
				<country-flag country='it' size='normal'/>
			</a>
			<a href="#" @click="changeLanguage('en')" title="English">
				<country-flag country='gb' size='normal'/>
			</a>
		</div>
	</div>
</template>

<script>

export default {
    data() {
        return {

        }
    },

	methods: {
		changeLanguage(lang) {
			this.$store.dispatch('changeLanguage', {language: lang});
		}
	},
}
</script>

<style scoped>
.f-it {
	margin-right: 1px !important;
}
</style>
