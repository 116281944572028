<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark text-white mb-5">
            <div class="container-fluid" v-if="$store.getters.isLoggedIn">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarScroll">
                    <!-- Toolbar logo -->
                    <img class="img-responsive logo d-none d-lg-block" src="@/assets/logo_brand.png" />
                    
                    <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">                        
                        <!-- Links -->
                        <template v-for="(link, index) in menu">                            
                            <li
                                v-if="
                                    // Link not limited to any school
                                    !link.schoolId && !link.schoolIds
                                ||
                                    // Link limited to one school only
                                    link.schoolId && link.schoolId == currentSchoolId
                                ||
                                    // Link limited to more than one school
                                    link.schoolIds && link.schoolIds.includes(currentSchoolId)
                                "
                                class="nav-item" :class="{'dropdown': link.submenu}" :key="index"
                            >                            
                                <!-- Single links -->
                                <template v-if="!link.submenu">
                                    <router-link :to="link.to" class="nav-link"
                                        :class="{
                                        'active': $route.path == link.to
                                        }"
                                    >
                                        {{link.label}}
                                    </router-link>
                                </template>

                                <!-- Dropdowns -->
                                <template v-else>
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{link.label}}
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                                        <li v-for="(sublink, subindex) in link.submenu" :key="subindex">
                                            <router-link :to="sublink.to" class="dropdown-item"
                                                :class="{
                                                'active': $route.path == sublink.to
                                                }"
                                            >
                                                {{sublink.label}}
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>                                
                            </li>
                        </template>                   

                        <li class="nav-item">
                            <a class="nav-link" href="#" @click="$store.dispatch('logout')">
                                {{t('menu.logout')}}
                            </a>
                        </li>
                    </ul>

                    <language-selector />
                </div>
            </div>
        </nav>

          
    </div>
</template>

<script>

import config from './../config'
import LanguageSelector from './LanguageSelector';

export default {
    components: {
        LanguageSelector
    },

    data() {
        return {
            config,
        }
    },

    computed: {
        menu() {
            const links = [
                {label: this.t('menu.home'), to: '/index'},
                {label: this.t('menu.newStudent'), to: '/students/new'},
                {label: this.t('menu.studentIndex'), to: '/students'},
                {label: this.t('menu.reviews'), to: '/reviews', schoolIds: this.config.schoolsWithReviews},
            ];
            return links;
        },

        currentSchoolId() {
            return this.$store.getters.currentSchoolId;
        }
    },
}
</script>

<style scoped>
.logo {
    max-height: 25px;
}
</style>
