<template>
    <div v-if="$debug && !forceHide">
        <div class="container mt-6">
            <div class="row">
                <div class="col">
                    <i>## Debug ##</i>
                    
                    <br />

                    <slot />
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    data() {
        return {
            forceHide: false
        }
    }    
}
</script>

<style scoped>
.container {
    background-color: lightyellow;
    border: 1px solid orange;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
}

i {
    color: lightsalmon;
}
</style>